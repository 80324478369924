<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div style="height: 48px;">
        <b-button
          size="sm"
          variant="primary"
          class="mr-2"
          @click="
            $router.push({
              name: 'Редактирование локации',
              params: { id: '0' }
            })
          "
        >
          <i class="icon-plus"></i> Добавить
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              loadData(false);
            }
          "
        >
          <i class="icon-reload"></i> Обновить
        </b-button>
      </div>

      <GMap ref="locationsMap" :draggable="false" />
      <Grid
        ref="locationsGrid"
        :items="gridData.items"
        :columns="gridData.columns"
        :classes="gridData.classes"
        :actions="gridData.actions"
        :index="gridData.index"
        :filter-key="gridData.searchQuery"
        :defaultSort="gridData.sortBy"
        :defaultSortOrder="-1"
        :isDataLoading="isPageLoading"
      ></Grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import API from "@/api/stalk.net";
import Grid from "@/components/common/Grid";
import GMap from "@/components/common/GMap";

export default {
  name: "LocationsList",
  components: {
    Grid,
    GMap
  },
  data() {
    return {
      isPageLoading: false,
      gridData: {
        items: [],
        columns: [
          { title: "Id", path: "Id" },
          { title: "Название", path: "Title" },
          {
            title: "Тип локации",
            path: "Type",
            handler: str => {
              return API.getLocationTypeTitle(str);
            }
          },
          {
            title: "Дата создания",
            path: "CreatedAt",
            handler: str => {
              return Vue.filter("formatDate")(str);
            }
          }
        ],
        index: { title: { path: "Title" }, id: { path: "Id" } },
        classes: [
          {
            path: "Zone",
            equals: API.consts.LocationZones.Red,
            type: "table-red"
          },
          {
            path: "Zone",
            equals: API.consts.LocationZones.Yellow,
            type: "table-yellow"
          },
          {
            path: "Zone",
            equals: API.consts.LocationZones.Green,
            type: "table-green"
          },
          {
            path: "Zone",
            equals: API.consts.LocationZones.Blue,
            type: "table-blue"
          }
        ],
        actions: [
          {
            name: "location_edit",
            title: "Редактировать",
            disabled: !this.isActionAvailable("location_edit"),
            filter: this.isCanEdit,
            action: this.edit
          }
        ],
        searchQuery: "",
        sortBy: {}
      },
      baseURL: API.baseURL,
      markers: []
    };
  },
  methods: {
    loadData(sort = true) {
      let self = this;

      self.isPageLoading = true;
      API.public
        .findLocations()
        .then(resp => {
          self.gridData.items = resp.data;
          if (sort) {
            self.gridData.sortby = { title: "Id", path: "Id" };
            self.$refs.locationsGrid.sortBy(self.gridData.sortby);
          }

          self.prepareMarkers();
          self.isPageLoading = false;
        })
        .catch(this.catchError);
    },
    prepareMarkers() {
      let self = this;

      self.gridData.items.forEach(itm => {
        if (itm.GPS.Id > 0)
          self.$refs.locationsMap.AddGPSMarker(itm.GPS, itm.Title);
      });
    },
    viewUserDataByRequestId(rid) {
      let self = this;

      API.private
        .getUserByRequest(rid)
        .then(resp => {
          self.selectedUser = resp.data;
          // self.selectedUser = resp.data.records;
          self.$refs.userInfoModal.show();
        })
        .catch(self.catchError);
    },
    viewMemberDataByRequestId(rid) {
      let self = this;

      API.private
        .getMemberByRequest(rid)
        .then(resp => {
          self.selectedMember = resp.data.records;
          self.$refs.memberInfoModal.show();
        })
        .catch(this.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (
        e.response &&
        e.response.data.records &&
        e.response.data.records.error
      ) {
        console.error(e.response.data.records);
        switch (e.response.data.records.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    },
    isActionAvailable(name) {
      let requests = localStorage.getItem("actions");
      return requests.includes(name);
    },
    isCanEdit() {
      return true;
    },
    edit(item) {
      this.$router.push({
        name: "Редактирование локации",
        params: { id: item.Id }
      });
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
