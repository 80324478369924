<template>
  <div>
    <Map
      v-if="markers.length > 0"
      :center="markers[0]"
      :zoom="17"
      map-type-id="hybrid"
      style="width: 500px; height: 300px"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }"
    >
      <GmapMarker
        :key="index"
        :label="titles[index]"
        v-for="(m, index) in markers"
        :position="m"
        :clickable="true"
        :draggable="draggable"
        @click="center = m"
      />
    </Map>
  </div>
</template>

<script>
import { gmapApi, Map, Marker as GmapMarker } from "vue2-google-maps";

export default {
  name: "GMap",
  components: {
    Map,
    GmapMarker
  },
  props: {
    draggable: Boolean
  },
  data() {
    return {
      center: {},
      markers: [],
      titles: []
    };
  },
  computed: {
    google: gmapApi
  },
  filters: {},
  methods: {
    AddGPSMarker(gps, title) {
      let self = this;
      if (gps.Id > 0) {
        let m =
          self.google &&
          new self.google.maps.LatLng(gps.Latitude, gps.Longitude);

        self.markers.push(m);
        self.titles.push(title);
      }
    }
  }
};
</script>

<style scoped></style>
